// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-main-container {
    display: flex;
    z-index: 1400;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    align-items: center;
    justify-content: center;
    color: #0097A7;
    padding-top: 250px;
}
.progress-main-container .coverer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    z-index: 1412;

  }
.progress-main-container .progress-bar-container{
    display: flex;
    flex-direction: column;
    width: 30%;
    z-index: 1450;
}

.progress-main-container span {
    margin-top: 20px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .progress-main-container .progress-bar-container{
        width: 50%;
    }
}

@media screen and (max-width: 527px) {
}

@media screen and (max-width: 400px) {
    .progress-main-container .progress-bar-container{
        width: 70%;
    }
}

@media screen and (max-width: 350px) {
}
`, "",{"version":3,"sources":["webpack://./src/components/common/ProgressBar/ProgressBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,WAAW;IACX,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAE9B,kBAAkB;IAClB,aAAa;;EAEf;AACF;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;AACA;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;AACA","sourcesContent":[".progress-main-container {\n    display: flex;\n    z-index: 1400;\n    flex-direction: column;\n    position: fixed;\n    width: 100%;\n    height: 100vh;\n    top: 0px;\n    align-items: center;\n    justify-content: center;\n    color: #0097A7;\n    padding-top: 250px;\n}\n.progress-main-container .coverer::before {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.8);\n    z-index: 1;\n    border-radius: 8px;\n    z-index: 1412;\n\n  }\n.progress-main-container .progress-bar-container{\n    display: flex;\n    flex-direction: column;\n    width: 30%;\n    z-index: 1450;\n}\n\n.progress-main-container span {\n    margin-top: 20px;\n    text-align: center;\n}\n\n@media screen and (max-width: 600px) {\n    .progress-main-container .progress-bar-container{\n        width: 50%;\n    }\n}\n\n@media screen and (max-width: 527px) {\n}\n\n@media screen and (max-width: 400px) {\n    .progress-main-container .progress-bar-container{\n        width: 70%;\n    }\n}\n\n@media screen and (max-width: 350px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
